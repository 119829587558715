/**
 * Action event on fields.
 */
(function ($) {
    /**
     * Use on forms Contact to hidden or display fields.
     *
     * @see template templates/forms/form-contacts.php
     */
    $('input[name=client-company]').change(function () {
        if (this.value === '1') {
            $('.hidden-fields').removeClass('hidden');
        } else {
            $('.hidden-fields').addClass('hidden');
        }
    });

    /**
     * Hide or show the fields for mediaforce clients.
     * Fields for mediaforce client is show on checked checkbox.
     *
     */
    $('input[name=client-mediforce]').change(function () {
        if (this.checked) {
            $('.hidden-fields').removeClass('hidden');
        } else {
            $('.hidden-fields').addClass('hidden');
        }
    });

    /**
     * Hide or show field amount-desired.
     */
    $('input[name=reserve-change]').change(function () {
        if (this.value === 'reserve-modify') {
            $('.js-account_amount').removeClass('hidden');
        } else {
            $('.js-account_amount').addClass('hidden');
        }
    });

    /**
     * Ajax request to retrieve amounts of profession given.
     *
     * @param {string} profession Profession name.
     * @return {*} Return an object retrieved from a JSON response back to the ajax request : {
     *  @type {boolean} success Success will be true on success or false on failure.
     *  @type {object} data  the data retrieve on success is true : {
     *    @type {array} amounts Array contain list of amounts allowed for the profession
     *    @type {int} amountMax The amount max allowed for the professions.
     *   }
     * }
     */
    function professionAmount(profession) {
        var nonce = $('input[name=_cmvmFormsNonce]').val();
        // retrieve ajax_url from cmvm_forms_ajax if is undefined.
        var ajax_url = cmvm_forms_ajax.ajax_url;

        return $.ajax({
            'url': ajax_url,
            type: 'post',
            data: {
                'action': 'profession_amount',
                'profession': profession,
                'ActionNonce': nonce
            }
        });
    }

    /**
     * Update the list of amounts of the reserve with data recovered in ajax
     *
     * @summary Update list reserve amount for profession.
     * @see professionAmount
     */
    function updateReserveAmount(profession) {
        professionAmount(profession).done(function (response) {
            if (response.success && response.data) {
                var amounts = response.data.amounts;
                var reserve_amount = $('select#reserve_amount[name=amount-desired]');
                // Remove all option except the first element.
                reserve_amount.children('option:not(:first)').remove();
                $.each(amounts, function (key,value) {
                    // Add all the new value to select element.
                    reserve_amount.append($('<option></option>').attr('value',value).text(value));
                });
            }
        });
    }

    // Handler list profession on form account evolution.
    var professionAccountEvolution = $('form#account-evolution select#professions');
    if (professionAccountEvolution.length) {
        // Triggered on DOM is load and load update reserve amount with profession selected.
        $(document).ready(function () {
            updateReserveAmount($('form#account-evolution select#professions option:selected').val());
        });

        // Triggered at the event 'change` on the list of professions.
        professionAccountEvolution.change(function () {
            updateReserveAmount(this.value);
        });
    }

    /**
     * Triggered at the event `change` on the list of professions
     * and update the amount max string with data retrieve from ajax request.
     *
     * @see professionAmount.
     */
    function updateAmountMax(profession){
        professionAmount(profession).done(function (response) {
            if (response.success && response.data) {
                var amountMax = response.data.amountMax;
                // Update the amount from the template.
                $('#amountMax').text(amountMax);
            }
        });
    }

    // Handler list profession increase cash reserve.
    var professionIncreaseCashReserve = $('form#increase-cash-reserve select#professions');
    if (professionIncreaseCashReserve.length) {
        $(document).ready(function () {
            updateAmountMax($('form#increase-cash-reserve select#professions option:selected').val());
        });
        // Triggered at the event 'change` on the list of professions.
        professionIncreaseCashReserve.change(function () {
            updateAmountMax(this.value);
        });
    }
    /**
     * Add space between Number.
     *
     * @param obj the input object
     * @param size The desired grouped number.
     */
    function addSpaceBetweenNumber(obj,size) {
        obj.value = obj.value.replace(/ /g,'');
        var number = obj.value;
        var regex = new RegExp('\\B(?=(\\d{'+size+'})+(?!\\d))',"g");
        obj.value = number.replace(regex, " ");
    }

    /**
     * Add space between siret number.
     */
    $('input[type=text].siret').keyup(function () {
        addSpaceBetweenNumber(this,3);
    });
    /**
     * Add space between phone number
     */
    $('input[type=text].phone').keyup(function () {
        addSpaceBetweenNumber(this,2);
    });

    /**
     * Show research form
     */
    $('.button-search-form').click(function (event) {
        $('header .search-form').css("display","flex");
        $('body > header').append('<div class="overlay-form"></div>');
        event.preventDefault();
    });
    $('body').on('click','.overlay-form', function (event) {
        $('header .search-form').css("display","none");
        $(this).remove();
        event.preventDefault();
    });

})(jQuery);
